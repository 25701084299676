.btn {
    display: flex;
    height: 45px;
    line-height: 45px;
    max-width: 320px;
    border-radius: var(--general-border-radius);
    padding: 0 20px;
    background: var(--button-background-color);
    color: var(--button-text-color);
    text-decoration: none;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
    margin: 0 auto;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    transition: background ease-in-out .3s;
    font-family: var(--font-family);
    box-shadow: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    i+span {
        margin-left: 10px;
    }

    span {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &:hover {
        text-decoration: none;
        background: ~'rgba(var(--button-background-color-rgb), 0.8)';
    }

    &:disabled {
        background: var(--inactive-color);

        &:hover {
            background: ~'rgba(var(--inactive-color-rgb), 0.8)';
            cursor: not-allowed;
        }

        &.loading {
            background: ~'rgba(var(--inactive-color-rgb), 0.8)';
        }
    }
}

button.btn {
    width: 100%;
}

.btn+.btn {
    margin-top: 10px;
}