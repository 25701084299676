.rl-select {
    display: block;
    height: 45px;
    width: 100%;
    box-sizing: border-box;
    position: relative;

    .rl-select-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        width: 100%;
        cursor: pointer;
        box-sizing: border-box;
        padding: 0 30px 0 10px;
        border-radius: var(--general-border-radius);
        border: 1px solid transparent;
        background: var(--background-highlight-color);
        transition: border-color ease-in-out .3s;
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 5px 0 5px;
            border-color: var(--general-inactive-color) transparent transparent transparent;
        }

        &:hover {
            border-color: var(--general-border-color);
        }

        &.placeholder span {
            color: var(--general-text-color);
            opacity: .5;
        }

        >img {
            height: 26px;
            width: 26px;
            margin-right: 10px;
        }
    }

    .rl-select-overlay {
        display: none;
    }

    .rl-select-options {
        position: absolute;
        max-height: 400px;
        top: calc(100% + 5px);
        border-radius: var(--general-border-radius);
        box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15);
        transition: 0.15s linear opacity;
        max-width: 100%;
        min-width: 300px;
        z-index: 4;
        display: none;
        flex-direction: column;
        width: 100%;
        border: 1px solid var(--general-border-color);
        background: var(--white-color);
        overflow: hidden;

        &.show {
            display: flex;
        }

        .rl-select-options-scroll-container {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .rl-select-option {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 45px;
            padding: 0 15px;
            cursor: pointer;


            &:hover {
                background: var(--background-highlight-color);
            }

            &:focus {
                background: ~'rgba(var(--background-highlight-color-rgb), .7)';
                outline-style: none;
                outline: none;
            }

            >img {
                width: 26px;
                height: 26px;
                display: block;
                margin-right: 10px;
            }

            >span {
                margin: 0 auto 0 0;
                line-height: 1;
                color: var(--text-on-white-color);
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .radio {
                width: 18px;
                height: 18px;
                border-radius: 100%;
                flex: 0 0 auto;
                border: 2px solid var(--general-border-color);
            }

            &.selected {
                >span {
                    color: var(--highlight-color);
                    font-weight: bold;
                }

                .radio {
                    position: relative;
                    border: 2px solid var(--highlight-color);

                    &:after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 8px;
                        height: 8px;
                        background: var(--highlight-color);
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .rl-select {
        .rl-select-options {
            position: fixed;
            max-height: 40vh;
            top: auto;
            bottom: 0;
            padding-top: 15px;
            padding-bottom: 15px;
            padding-bottom: calc(15px + env(safe-area-inset-bottom));
            left: 0;
            right: 0;
            width: 100%;
            max-width: 100%;
            min-width: 100%;
            height: 100%;
            display: flex;
            border-radius: 15px 15px 0 0;
            box-shadow: 0 7px 15px rgba(60, 72, 88, 0.1);
            transform: translate(0, 100%);
            transition: 0.2s cubic-bezier(0, 1, 0.5, 1) transform;

            &.show {
                transform: translate(0, 0);
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 10px;
                width: 42px;
                height: 5px;
                background: var(--text-on-white-color);
                opacity: 0.3;
            }
        }

        .rl-select-overlay {
            &.show {
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 3;
                background: rgba(0, 0, 0, 0.4);
            }
        }
    }

}