.universal-body {
    background-color: var(--general-background-color);
    background-image: none;
}

.universal-wrapper {
    height: 100%;
    display: flex;
    width: 100%;
    overflow: hidden;
    position: relative;
    flex-direction: column;

    .universal-body-container {
        flex: 1 1 auto;
        width: 100%;
        height: 100%;
        background-color: #000;
        background-image: url('/assets/universal-bg.png');
        background-size: cover;
        background-position: center bottom;
        display: flex;
        flex-direction: column;

        .logo-container {
            height: 55%;
            width: 100%;
            flex: 0 1 auto;
            align-items: center;
            justify-content: flex-end;
            display: flex;
            flex-direction: column;

            h1 {
                display: block;
                width: 100%;
                box-sizing: border-box;
                text-align: center;
                padding: 0 15px;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 16px;
                margin: 0;
                color: #fff;
            }

            img {
                width: 250px;
                height: 60px;
                margin-top: 10px;
            }
        }

        .slider-container {
            height: 45%;
            width: 100%;
            position: relative;
            flex: 0 1 auto;

            &:before {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 3;
            }

            .slide {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0;
                z-index: 2;
                transition: all ease-in-out 1s;

                &.active {
                    opacity: 1;
                    z-index: 4;
                }
            }

            .slide-body {
                width: 280px;
                height: auto;
                max-height: 100%;
                text-align: center;
                margin: 0;
                padding: 10px;
                font-weight: 400;
                color: #fff;
                font-size: 16px;
                box-sizing: border-box;
                overflow-x: hidden;
                overflow-y: auto;

                a {
                    display: inline;
                    color: var(--highlight-color);
                }
            }
        }
    }

    .universal-form-container {
        min-height: 230px;
        height: 230px;
        width: 100%;
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        form {
            width: 100%;
            max-width: 340px;
            margin: 0 auto;
            padding: 30px 15px;
            box-sizing: border-box;

            .form-group {
                width: 100%;
                position: relative;
            }

            .input-remove-btn {
                position: absolute;
                display: none;
                font-size: 18px;
                cursor: pointer;
                top: 0;
                right: 0;
                height: 45px;
                line-height: 45px;
                width: 40px;
                text-align: center;
                color: var(--general-text-color);

                &.show {
                    display: block;
                }
            }

            input[type=text] {
                margin-bottom: 12px;
                padding-right: 40px;
            }

            label {
                text-align: center;
                display: block;
                width: 100%;
                margin-bottom: 14px;
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    .universal-login-error {
        margin: -5px 0 9px 0;

        ul {
            display: block;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                display: block;
                color: var(--warning-color);
                font-size: 13px;
            }
        }
    }
}

.universal-help-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
    border: 0;
    box-shadow: none;
    width: 55px;
    height: 55px;
    line-height: 55px;
    background: none;
    color: #fff;
    font-size: 25px;
    cursor: pointer;

    &:hover {
        opacity: .8;
    }
}

.universal-help-container {
    position: fixed;
    z-index: 6;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    color: #2d2d2d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    display: none;
    opacity: 0;
    transition: opacity ease-in-out .3s;

    &.active {
        display: flex;

        &.show {
            opacity: 1;
        }
    }

    .universal-help-button {
        color: #2d2d2d;
    }

    .universal-help-body {
        padding: 0 20px;
        width: 100%;
        box-sizing: border-box;
        max-width: 400px;
        flex: 0 0 auto;
        margin: 0 auto;

        h2 {
            font-size: 25px;
            font-weight: 500;
            margin: 0;

            img {
                width: 20px;
                height: 20px;
                display: inline-block;
                vertical-align: top;
                position: relative;
                top: 5px;
                margin-left: 2px;
            }
        }

        p {
            margin: 15px 0;
        }
    }
}

/* RESPONSIVE */
@media only screen and (min-width: 767px) {
    .universal-wrapper {
        .universal-body-container {
            .logo-container {
                height: 50%;
                box-sizing: border-box;
                padding-bottom: 150px;
            }

            .slider-container {
                height: 50%;
                box-sizing: border-box;

                .slide {
                    top: 115px;
                    bottom: auto;
                    height: 180px;

                    .slide-body {
                        width: 320px;
                    }
                }
            }
        }

        .universal-form-container {
            position: absolute;
            width: 500px;
            max-width: 500px;
            top: 50%;
            left: calc(50% - 250px);
            margin-top: -115px;
            background: var(--general-background-color);
            border-radius: var(--general-border-radius);
            box-shadow: 0px 7px 15px 0px rgba(60, 72, 88, 0.102);
        }
    }
}