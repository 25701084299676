@font-face {
    font-family: "Avenir Next";
    src: url("/assets/fonts/Avenir/069faa0e-9913-48c4-9ef7-89a4bc080b65.eot?#iefix");
    src: url("/assets/fonts/Avenir/069faa0e-9913-48c4-9ef7-89a4bc080b65.eot?#iefix") format("eot"), url("/assets/fonts/Avenir/7db1f672-3a8f-4d19-9c49-7f61aed450b5.woff2") format("woff2"), url("/assets/fonts/Avenir/4ab86b35-c0c2-42b5-98ad-4b6eba66b197.woff") format("woff"), url("/assets/fonts/Avenir/276b3566-1c3b-4bc1-8915-15314f091f29.ttf") format("truetype"), url("/assets/fonts/Avenir/5d02f5f4-46e7-453a-aef9-3e7106d7bb68.svg#5d02f5f4-46e7-453a-aef9-3e7106d7bb68") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Avenir Next";
    src: url("/assets/fonts/Avenir/78f55966-cc8e-4f4c-bf8b-8fe59be9fe96.eot?#iefix");
    src: url("/assets/fonts/Avenir/78f55966-cc8e-4f4c-bf8b-8fe59be9fe96.eot?#iefix") format("eot"), url("/assets/fonts/Avenir/b17468ea-cf53-4635-984b-4d930a68ed4d.woff2") format("woff2"), url("/assets/fonts/Avenir/4d1d0d0d-9ea6-4117-901f-8b32ca1ab936.woff") format("woff"), url("/assets/fonts/Avenir/66b50093-e606-427c-a42a-a44b2f9ff219.ttf") format("truetype"), url("/assets/fonts/Avenir/78695677-7ebb-4ef8-8996-eff09dc64f26.svg#78695677-7ebb-4ef8-8996-eff09dc64f26") format("svg");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Avenir Next";
    src: url("/assets/fonts/Avenir/c6970a14-8b0f-4629-9072-71c7e123908f.eot?#iefix");
    src: url("/assets/fonts/Avenir/c6970a14-8b0f-4629-9072-71c7e123908f.eot?#iefix") format("eot"), url("/assets/fonts/Avenir/b0b84e4d-2164-45c7-a674-1662f19f3ba6.woff2") format("woff2"), url("/assets/fonts/Avenir/e91d1bbf-3fea-45e2-b003-a22b12ce6e5f.woff") format("woff"), url("/assets/fonts/Avenir/ead8b64b-1abd-4d5b-a642-a21dfe2f463b.ttf") format("truetype"), url("/assets/fonts/Avenir/e536e1c2-92a4-4db4-8a41-1c55354d11b7.svg#e536e1c2-92a4-4db4-8a41-1c55354d11b7") format("svg");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Avenir Next";
    src: url("/assets/fonts/Avenir/1f022c78-180e-4c6b-b5ee-e1573f17e4b6.eot?#iefix");
    src: url("/assets/fonts/Avenir/1f022c78-180e-4c6b-b5ee-e1573f17e4b6.eot?#iefix") format("eot"), url("/assets/fonts/Avenir/349e5647-5161-46bb-a19f-8a609ae235e4.woff2") format("woff2"), url("/assets/fonts/Avenir/cc0a88c2-524b-4c90-b6f0-a80570222c30.woff") format("woff"), url("/assets/fonts/Avenir/03aefdc0-0198-4662-a9c7-640a4734063e.ttf") format("truetype"), url("/assets/fonts/Avenir/132a539d-37fa-48fb-92ec-1f4591f98ee1.svg#132a539d-37fa-48fb-92ec-1f4591f98ee1") format("svg");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Avenir Next";
    src: url("/assets/fonts/Avenir/fb4117a5-14ee-4035-8692-935bfff7fc2e.eot?#iefix");
    src: url("/assets/fonts/Avenir/fb4117a5-14ee-4035-8692-935bfff7fc2e.eot?#iefix") format("eot"), url("/assets/fonts/Avenir/4c04fb14-abdb-4f93-82bb-734d1b84307b.woff2") format("woff2"), url("/assets/fonts/Avenir/04d2f223-2786-40c9-8481-be9dd47d8e7f.woff") format("woff"), url("/assets/fonts/Avenir/bce3274e-f498-48ae-b269-a6930df496e7.ttf") format("truetype"), url("/assets/fonts/Avenir/68f0375d-f66f-4f88-822a-5a0b61d2efc0.svg#68f0375d-f66f-4f88-822a-5a0b61d2efc0") format("svg");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Avenir Next";
    src: url("/assets/fonts/Avenir/8316f326-afe6-49dc-a5d8-cd1d521182c2.eot?#iefix");
    src: url("/assets/fonts/Avenir/8316f326-afe6-49dc-a5d8-cd1d521182c2.eot?#iefix") format("eot"), url("/assets/fonts/Avenir/395aeb70-3407-45d6-91b3-3d9a114af9bd.woff2") format("woff2"), url("/assets/fonts/Avenir/5f0686cb-9605-4656-8b38-9b8ca092ca9b.woff") format("woff"), url("/assets/fonts/Avenir/5fafc2a7-1ec9-4ba2-8d0b-836dfae0023b.ttf") format("truetype"), url("/assets/fonts/Avenir/f2ab6bd3-14da-4c86-949e-eddf030a8147.svg#f2ab6bd3-14da-4c86-949e-eddf030a8147") format("svg");
    font-weight: 700;
    font-style: italic;
}


// AvenirNextLTPro
@font-face {
    font-family: "AvenirNextLTPro";
    src: url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.eot?#iefix");
    src: url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.eot?#iefix") format("eot"), url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.woff2") format("woff2"), url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.woff") format("woff"), url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.ttf") format("truetype"), url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "AvenirNextLTPro";
    src: url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Demi.eot?#iefix");
    src: url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Demi.eot?#iefix") format("eot"), url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Demi.woff2") format("woff2"), url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Demi.woff") format("woff"), url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Demi.ttf") format("truetype"), url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Demi.svg#AvenirNextLTPro-Demi") format("svg");
    font-weight: 500;
    font-style: normal;
}