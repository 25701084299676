.multibrand-body {
    display: flex;
    background-color: var(--general-background-color);
}

.multibrand-wrapper {
    max-width: 480px;
    width: 100%;
    margin: auto;

    .logo-container-empty {
        display: block;
        width: 100%;
        height: 40px;
    }

    .logo-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 480px;
        width: 100%;
        box-sizing: border-box;
        height: 150px;
        margin: 0 auto;
        padding: 30px 40px;

        .logo {
            display: block;
            max-height: 100%;
            max-width: 100%;
        }
    }

    .intro-text {
        display: block;
        max-width: 480px;
        width: 100%;
        box-sizing: border-box;
        padding: 0 20px;
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        margin: 0 auto 25px auto;
    }

    .description-text {
        display: block;
        max-width: 480px;
        width: 100%;
        box-sizing: border-box;
        padding: 0 20px;
        text-align: center;
        margin: 0 auto 25px auto;
        font-weight: 300;
        font-size: 14px;
    }

    .app-container {
        display: flex;
        max-width: 480px;
        width: 100%;
        box-sizing: border-box;
        padding: 0 20px;
        flex-direction: row;
        align-content: flex-start;
        flex-wrap: wrap;
        justify-content: center;

        .app {
            display: flex;
            flex: 0 0 auto;
            width: 50%;
            margin-bottom: 20px;
            text-decoration: none;
            flex-direction: column;

            &:hover {
                .app-icon {
                    opacity: .8;
                }
            }

            .app-icon {
                display: block;
                margin: 0 auto;
                width: 100px;
                height: 100px;
                background-position: center center;
                background-size: cover;
                border-radius: 20px;
                box-shadow: 0px 7px 15px 0px rgba(60, 72, 88, 0.3);
                transition: opacity ease-in-out .3s;
            }

            .app-name {
                display: block;
                margin: 10px auto 0 auto;
                text-align: center;
                color: var(--general-text-color);
                font-weight: bold;
                padding: 0 10px;
            }
        }
    }
}

@media only screen and (min-width: 480px) {
    .multibrand-wrapper .app-container .app {
        width: 33%;
    }
}

@media only screen and (min-width: 768px) {
    .multibrand-wrapper .logo-container {
        position: relative;
        margin-bottom: -100px;
        top: -150px;
        margin-top: 150px;
    }

    .multibrand-body {
        background-color: var(--desktop-background-color);
    }

    .multibrand-body-container {
        background: var(--general-background-color);
        border-radius: var(--general-border-radius);
        box-shadow: 0px 7px 15px 0px rgba(60, 72, 88, 0.102);
        padding: 0 15px 20px 15px;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}