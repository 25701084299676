:root {
    --logo: url('/assets/svg/logo-dark.svg');
    --desktop-logo: url('/assets/svg/logo-dark.svg');

    --font-familiy: 'AvenirNextLTPro', 'Avenir Next', 'Arial', 'Verdana', 'Helvetica', 'sans-serif';

    --general-text-color: #3C4858;
    --general-text-color-rgb: 60, 72, 88;
    --general-background-color: #FFFFFF;
    --general-background-color-rgb: 255, 255, 255;

    --desktop-background: none;
    --desktop-background-color: #F7F8FA;
    --desktop-background-color-rgb: 247, 248, 250;
    --desktop-text-color: #3C4858;
    --desktop-text-color-rgb: 60, 72, 88;

    --general-border-radius: 7px;
    --general-border-color: ~'rgba(var(--general-text-color-rgb), .1)';
    --general-inactive-color: ~'rgba(var(--general-text-color-rgb), .4)';


    --highlight-color: #EA6F63;
    --highlight-color-rgb: 234, 111, 99;
    --background-highlight-color: #F5F5F5;
    --background-highlight-color-rgb: 245, 245, 245;
    --text-on-highlight-color: #FFFFFF;
    --text-on-highlight-color-rgb: 255, 255, 255;

    --inactive-color: #ACACAC;
    --inactive-color-rgb: 172, 172, 172;
    --alert-color: #FED263;
    --alert-color-rgb: 254, 210, 99;
    --warning-color: #EB6262;
    --warning-color-rgb: 235, 98, 98;

    --button-background-color: #EA6F63;
    --button-background-color-rgb: 234, 111, 99;
    --button-text-color: #FFFFFF;
    --button-text-color-rgb: 255, 255, 255;

    --native-statusbar-background-color: var(--general-desktop-background-color);
    --native-statusbar-text-color: var(--general-text-color);

    --black-color: #000000;
    --black-color-rgb: 0, 0, 0;
    --white-color: #FFFFFF;
    --white-color-rgb: 255, 255, 255;
    --text-on-white-color: #3C4858;
    --text-on-white-color-rgb: 60, 72, 88;
}