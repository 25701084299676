.login-wrapper {
    max-width: 500px;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    padding: 0 10px;
    margin: auto;

    .login-logo-container {
        display: block;
        max-width: 320px;
        width: 100%;
        box-sizing: border-box;
        height: 90px;
        margin: 0 auto 40px auto;
        padding: 0 20px;

        .login-logo {
            background-image: var(--desktop-logo);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center bottom;
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    .login-headline {
        text-align: center;
        font-size: 18px;
        color: var(--desktop-text-color);
        margin: -20px auto 40px auto;
        padding: 0;
        box-sizing: border-box;
    }
}

.login-copyright {
    height: 70px;
    width: 100%;
    text-align: center;
    font-size: 13px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
        margin: 0;
        padding: 0;
        color: var(--desktop-text-color);
        display: block;
    }

    a,
    b {
        color: var(--desktop-text-color);
        margin: 0;
        font-weight: bold;
        text-decoration: none;
        display: block;
    }

    a:hover {
        text-decoration: underline;
    }
}


.login-footer {
    text-align: center;
    margin-top: 15px;
    font-size: 14px;

    a {
        display: block;
        margin-bottom: 10px;
        text-decoration: none;
        color: var(--general-text-color);
        cursor: pointer;

        i {
            margin-right: 5px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    button {
        font-family: 'AvenirNextLTPro', 'Avenir Next', 'Arial', 'Verdana', 'Helvetica', 'sans-serif';
        background: none;
        border: none;
        text-decoration: none;
        color: var(--general-text-color);
        font-size: 14px;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        i {
            margin-right: 5px;
        }
    }

    .switch-account {
        background: none;
        border: none;
        display: inline-block;
        height: 20px;
        line-height: 20px;
        box-sizing: border-box;
        font-size: 14px;
        text-decoration: underline;
        color: var(--highlight-color);

        &:hover {
            opacity: .8;
        }
    }
}

.login-container {
    background: var(--general-background-color);
    border-radius: var(--general-border-radius);
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    color: var(--general-text-color);
    box-shadow: 0px 7px 15px 0px rgba(60, 72, 88, 0.102);


    section {
        display: block;
        padding: 20px 30px;
        box-sizing: border-box;

        &:first-child {
            padding-top: 35px;
        }

        &:last-child {
            padding-bottom: 35px;
        }

        h2 {
            margin: 0 0 16px 0;
            font-size: 24px;
            text-align: center;
        }

        h3 {
            margin: -10px 0 16px 0;
            font-size: 20px;
            text-align: center;
        }

        p {
            text-align: center;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        section a:not(.btn) {
            color: var(--general-text-color);
            text-decoration: none;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .section-split {
        display: block;
        margin: -10px 0 0 0;
        padding: 0;
        text-align: center;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 500;
        color: var(--general-text-color);
        opacity: .7;
    }
}

.styled-link {
    color: var(--button-background-color);
    text-decoration: none;
    cursor: pointer;

    &:disabled {
        color: var(--inactive-color);
    }

    &:hover:not(:disabled) {
        text-decoration: underline;
    }

    &.styled-link-padding {
        padding: 0 5px;
    }
}

.styled-link-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--button-background-color);
    font-family: var(--font-family);
    text-decoration: none;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    margin: 0 0 15px 0;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    font-weight: 300;

    span {
        display: inline-block;

        +i {
            margin-left: 6px;
        }
    }

    i {
        font-size: 12px;

        +span {
            margin-left: 6px;
        }
    }

    &:disabled {
        color: var(--inactive-color);
    }

    &:hover:not(:disabled) span {
        text-decoration: underline;
    }
}

.login-page {
    h1 {
        text-align: left;
        margin: 15px 0 20px 0;
    }

    form {
        margin: 0 auto;
        width: 100%;
        max-width: 420px;
        padding: 0 10px;
        box-sizing: border-box;

        fieldset {
            border: none;
            padding: 0;
            margin: 0;
        }

        input[type=text],
        input[type=tel],
        input[type=password],
        .form-control-static {
            max-width: 100%;
        }

        .btn {
            max-width: 100%;
        }
    }

    .form-intro {
        display: block;
        font-weight: bold;
        margin: 0 auto 25px auto;
        font-size: 16px;
        text-align: left;
        width: 100%;
        padding: 0;
        box-sizing: border-box;

        +.form-group-intro {
            margin-top: -10px;
        }
    }

    .form-group-intro {
        display: block;
        font-weight: 300;
        margin: 0 auto 15px auto;
        font-size: 14px;
        text-align: left;
        width: 100%;
        padding: 0;
        box-sizing: border-box;

        +.form-group {
            margin-top: 20px;
        }
    }

    .form-centered {
        text-align: center;
    }

    .form-group {
        margin: 0px 0 15px 0;

        +button[type="submit"] {
            margin-top: 25px;
        }
    }

    button[type="button"]+button[type="submit"] {
        margin-top: 25px;
    }

    input.password-control {
        padding-right: 40px;
    }

    .password-view-icon {
        height: 45px;
        line-height: 45px;
        float: right;
        width: 40px;
        text-align: center;
        margin-bottom: -45px;
        opacity: .7;
        cursor: pointer;
        position: relative;
        z-index: 2;
    }

    .password-view-icon:hover {
        opacity: 1;
    }

    .login-error {
        margin: -6px 0 12px 0;

        ul {
            display: block;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                display: block;
                color: #eb6262;
                font-size: 13px;
            }
        }
    }

    .login-remember {
        margin-top: -4px;
        margin-bottom: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .checkbox {
            flex: 0 0 auto;
        }

        a {
            display: inline-block;
            height: 20px;
            line-height: 20px;
            box-sizing: border-box;
            font-size: 14px;
            text-decoration: underline;
            color: var(--general-text-color);
            flex: 0 0 auto;
            margin-left: auto;
        }
    }

    a.back-button {
        display: inline-block;
        height: 20px;
        line-height: 20px;
        box-sizing: border-box;
        font-size: 14px;
        text-decoration: underline;
        color: var(--general-text-color);

        &:hover {
            opacity: .8;
        }
    }

    .hidden-submit {
        display: none !important;
    }
}

/* PROVIDER BUTTONS */
.provider-list {
    display: block;
    padding: 0 10px;
    list-style: none;
    margin: 0 auto;
    max-width: 420px;
    box-sizing: border-box;

    li {
        margin: 0 0 12px 0;
        padding: 0;
        list-style: none;

        .btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 10px 20px;
            max-width: 100%;
            height: auto;
            min-height: 45px;

            &:before {
                display: block;
                width: 24px;
                height: 24px;
                line-height: 24px;
                font-size: 20px;
                margin-right: 10px;
                position: relative;
                font-family: var(--fa-style-family-brands);
                text-align: center;
                flex: 0 0 auto;
            }

            &.provider-microsoft {
                background: #2E7AC8;
                color: #FFFFFF;

                &:before {
                    content: "\f17a";
                }
            }

            &.provider-google {
                background: #EB4132;
                color: #FFFFFF;

                &:before {
                    content: "\f1a0";
                }
            }

            &.provider-okta {
                background: #000000;
                color: #FFFFFF;

                &:before {
                    content: "\f0a3";
                    font-family: var(--fa-style-family-classic);
                    font-weight: 400;
                }
            }

            span {
                display: block;
                line-height: 16px;
                white-space: initial;
                flex: 1 1 auto;
            }
        }
    }
}

/* RESPONSIVE */
@media only screen and (max-width: 767px) {
    .page-wrapper {
        height: auto;
        background: var(--general-background-color);
    }

    .login-wrapper {
        max-width: 100%;
        padding: 0;
        display: block;
    }

    .login-wrapper .login-logo-container {
        max-width: 260px;
        margin-bottom: 20px;
        padding: 0 30px;

        .login-logo {
            background-image: var(--logo);
        }
    }

    .login-wrapper .login-headline {
        color: var(--general-text-color);
        font-size: 15px;
        margin-top: 0;
        padding: 0 20px;
        font-weight: normal;
    }

    .login-container {
        margin: 0;
        border-radius: 0;
        background: none;
        min-height: 150px;
        box-shadow: none;
    }

    .login-container section {
        padding: 15px 10px;

        &:first-child {
            padding-top: 12px;
        }

        &:last-child {
            padding-bottom: 12px;
        }
    }

    .login-copyright {
        height: 50px;
        font-size: 11px;

        p,
        a,
        b {
            color: var(--general-text-color);
        }
    }

    .login-footer {
        margin-top: 0;
    }

    .login-footer a,
    .login-footer button {
        color: var(--general-text-color);
    }
}

@media only screen and (min-width: 768px) {

    .login-page form,
    .provider-list {
        max-width: 400px;
    }
}