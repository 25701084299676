input[type=text],
input[type=tel],
input[type=password],
.form-control-static {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    height: 45px;
    line-height: 43px;
    box-sizing: border-box;
    width: 100%;
    max-width: 320px;
    padding: 0 10px;
    display: block;
    height: 45px;
    line-height: 45px;
    border-radius: var(--general-border-radius);
    border: 1px solid transparent;
    background: var(--background-highlight-color);
    padding: 0 15px 0 15px;
    box-sizing: border-box;
    font-size: 14px;
    position: relative;
    color: var(--general-text-color);
    outline: none;
    transition: border-color ease-in-out .3s;
    font-family: 'AvenirNextLTPro', 'Avenir Next', 'Arial', 'Verdana', 'Helvetica', 'sans-serif';
    box-shadow: none;

    &:hover {
        border-color: var(--general-border-color);
    }

    &::placeholder {
        color: var(--general-text-color);
        opacity: .5;
    }

    +i {
        font-size: 20px;
        position: absolute;
        top: 12px;
        left: 8px;
    }

    +img {
        height: 20px;
        width: 20px;
        position: absolute;
        top: 12px;
        left: 8px;
    }
}


.form-control-static {
    user-select: none;
    vertical-align: middle;
    cursor: not-allowed;

    span,
    +i {
        opacity: 0.4;
    }
}

input[type=text],
input[type=tel],
input[type=password] {
    &:focus {
        border-color: var(--general-inactive-color);
    }

    &.has-error {
        border-color: ~'rgba(var(--warning-color-rgb), 0.5)';

        &:focus {
            border-color: var(--warning-color);
        }
    }
}

.has-error {

    input[type=text],
    input[type=tel],
    input[type=password] {
        border-color: ~'rgba(var(--warning-color-rgb), 0.5)';

        &:focus {
            border-color: var(--warning-color);
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover input:-webkit-autofill:focus {
        border-color: var(--warning-color) !important;
    }
}

input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--general-text-color);
    /* Firefox */
    opacity: 1;
}

input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--general-text-color);
    opacity: 1;
}

input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--general-text-color);
    opacity: 1;
}

.form-group {
    display: block;

    &.form-group-with-icon {
        position: relative;

        input[type=text],
        input[type=tel],
        input[type=password],
        .form-control-static {
            padding-left: 40px;
        }
    }
}

/* PIN */
.form-control-pin {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    input[type="text"] {
        margin: 0 5px;
        flex: 1 1 auto;
        text-align: center;

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }

        &:not(:disabled):not(:focus) {
            border-color: var(--general-border-color);
        }
    }
}

.form-submitting .form-control-pin {
    input[type=text] {
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: opacityPulse;
    }
}

@keyframes opacityPulse {
    0% {
        opacity: 0.4;
    }

    50% {
        opacity: 0.9;
    }

    100% {
        opacity: 0.4;
    }
}

/* CHECKBOX */
.checkbox {
    display: inline-block;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    input[type=checkbox] {
        height: 1px;
        width: 1px;
        position: absolute;
        left: -9999px;
        top: 0;

        +span {
            display: inline-block;
            height: 20px;
            line-height: 20px;
            font-size: 14px;
            color: var(--general-text-color);
            vertical-align: top;
            opacity: 1;
            user-select: none;
            position: relative;

            &:before {
                display: inline-block;
                height: 20px;
                width: 20px;
                box-sizing: border-box;
                border-radius: var(--general-border-radius);
                background: transparent;
                border: 1px solid var(--general-border-color);
                opacity: 1;
                content: '';
                vertical-align: top;
                margin-right: 7px;
                transition: border ease-in-out .3s;
            }

            &:hover:before {
                border-color: var(--general-text-color) !important;
            }
        }

        &:checked+span {
            &:before {
                border-color: ~'rgba(var(--general-text-color-rgb), 0.6)';
            }

            &:after {
                content: '\f00c';
                font-family: var(--fa-style-family-classic);
                font-weight: 400;
                position: absolute;
                left: 4px;
                top: 1px;
                width: 17px;
                height: 9px;
                font-size: 15px;
            }
        }
    }
}