.rl-phone-select {
    display: flex;
    width: 100%;
    height: 45px;
    flex-direction: row;

    .rl-select {
        width: 120px;
        flex: 0 0 auto;

        &:after {
            content: '';
            display: block;
            position: absolute;
            height: 30px;
            width: 1px;
            background: var(--general-border-color);
            left: 119px;
            top: 50%;
            margin-top: -15px;
        }

        .rl-select-input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    input {
        flex: 1 1 auto;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding-left: 15px;
    }
}