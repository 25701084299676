.error-page {
    .center {
        text-align: center;
    }

    .request-id {
        display: block;
        text-align: center;
        font-size: 12px;
        opacity: .7;
        text-transform: uppercase;
    }

    form {
        text-align: center;
    }
}