:root {
  --logo: url('/assets/svg/logo-dark.svg');
  --desktop-logo: url('/assets/svg/logo-dark.svg');
  --font-familiy: 'AvenirNextLTPro', 'Avenir Next', 'Arial', 'Verdana', 'Helvetica', 'sans-serif';
  --general-text-color: #3C4858;
  --general-text-color-rgb: 60, 72, 88;
  --general-background-color: #FFFFFF;
  --general-background-color-rgb: 255, 255, 255;
  --desktop-background: none;
  --desktop-background-color: #F7F8FA;
  --desktop-background-color-rgb: 247, 248, 250;
  --desktop-text-color: #3C4858;
  --desktop-text-color-rgb: 60, 72, 88;
  --general-border-radius: 7px;
  --general-border-color: rgba(var(--general-text-color-rgb), .1);
  --general-inactive-color: rgba(var(--general-text-color-rgb), .4);
  --highlight-color: #EA6F63;
  --highlight-color-rgb: 234, 111, 99;
  --background-highlight-color: #F5F5F5;
  --background-highlight-color-rgb: 245, 245, 245;
  --text-on-highlight-color: #FFFFFF;
  --text-on-highlight-color-rgb: 255, 255, 255;
  --inactive-color: #ACACAC;
  --inactive-color-rgb: 172, 172, 172;
  --alert-color: #FED263;
  --alert-color-rgb: 254, 210, 99;
  --warning-color: #EB6262;
  --warning-color-rgb: 235, 98, 98;
  --button-background-color: #EA6F63;
  --button-background-color-rgb: 234, 111, 99;
  --button-text-color: #FFFFFF;
  --button-text-color-rgb: 255, 255, 255;
  --native-statusbar-background-color: var(--general-desktop-background-color);
  --native-statusbar-text-color: var(--general-text-color);
  --black-color: #000000;
  --black-color-rgb: 0, 0, 0;
  --white-color: #FFFFFF;
  --white-color-rgb: 255, 255, 255;
  --text-on-white-color: #3C4858;
  --text-on-white-color-rgb: 60, 72, 88;
}
@font-face {
  font-family: "Avenir Next";
  src: url("/assets/fonts/Avenir/069faa0e-9913-48c4-9ef7-89a4bc080b65.eot?#iefix");
  src: url("/assets/fonts/Avenir/069faa0e-9913-48c4-9ef7-89a4bc080b65.eot?#iefix") format("eot"), url("/assets/fonts/Avenir/7db1f672-3a8f-4d19-9c49-7f61aed450b5.woff2") format("woff2"), url("/assets/fonts/Avenir/4ab86b35-c0c2-42b5-98ad-4b6eba66b197.woff") format("woff"), url("/assets/fonts/Avenir/276b3566-1c3b-4bc1-8915-15314f091f29.ttf") format("truetype"), url("/assets/fonts/Avenir/5d02f5f4-46e7-453a-aef9-3e7106d7bb68.svg#5d02f5f4-46e7-453a-aef9-3e7106d7bb68") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next";
  src: url("/assets/fonts/Avenir/78f55966-cc8e-4f4c-bf8b-8fe59be9fe96.eot?#iefix");
  src: url("/assets/fonts/Avenir/78f55966-cc8e-4f4c-bf8b-8fe59be9fe96.eot?#iefix") format("eot"), url("/assets/fonts/Avenir/b17468ea-cf53-4635-984b-4d930a68ed4d.woff2") format("woff2"), url("/assets/fonts/Avenir/4d1d0d0d-9ea6-4117-901f-8b32ca1ab936.woff") format("woff"), url("/assets/fonts/Avenir/66b50093-e606-427c-a42a-a44b2f9ff219.ttf") format("truetype"), url("/assets/fonts/Avenir/78695677-7ebb-4ef8-8996-eff09dc64f26.svg#78695677-7ebb-4ef8-8996-eff09dc64f26") format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next";
  src: url("/assets/fonts/Avenir/c6970a14-8b0f-4629-9072-71c7e123908f.eot?#iefix");
  src: url("/assets/fonts/Avenir/c6970a14-8b0f-4629-9072-71c7e123908f.eot?#iefix") format("eot"), url("/assets/fonts/Avenir/b0b84e4d-2164-45c7-a674-1662f19f3ba6.woff2") format("woff2"), url("/assets/fonts/Avenir/e91d1bbf-3fea-45e2-b003-a22b12ce6e5f.woff") format("woff"), url("/assets/fonts/Avenir/ead8b64b-1abd-4d5b-a642-a21dfe2f463b.ttf") format("truetype"), url("/assets/fonts/Avenir/e536e1c2-92a4-4db4-8a41-1c55354d11b7.svg#e536e1c2-92a4-4db4-8a41-1c55354d11b7") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next";
  src: url("/assets/fonts/Avenir/1f022c78-180e-4c6b-b5ee-e1573f17e4b6.eot?#iefix");
  src: url("/assets/fonts/Avenir/1f022c78-180e-4c6b-b5ee-e1573f17e4b6.eot?#iefix") format("eot"), url("/assets/fonts/Avenir/349e5647-5161-46bb-a19f-8a609ae235e4.woff2") format("woff2"), url("/assets/fonts/Avenir/cc0a88c2-524b-4c90-b6f0-a80570222c30.woff") format("woff"), url("/assets/fonts/Avenir/03aefdc0-0198-4662-a9c7-640a4734063e.ttf") format("truetype"), url("/assets/fonts/Avenir/132a539d-37fa-48fb-92ec-1f4591f98ee1.svg#132a539d-37fa-48fb-92ec-1f4591f98ee1") format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next";
  src: url("/assets/fonts/Avenir/fb4117a5-14ee-4035-8692-935bfff7fc2e.eot?#iefix");
  src: url("/assets/fonts/Avenir/fb4117a5-14ee-4035-8692-935bfff7fc2e.eot?#iefix") format("eot"), url("/assets/fonts/Avenir/4c04fb14-abdb-4f93-82bb-734d1b84307b.woff2") format("woff2"), url("/assets/fonts/Avenir/04d2f223-2786-40c9-8481-be9dd47d8e7f.woff") format("woff"), url("/assets/fonts/Avenir/bce3274e-f498-48ae-b269-a6930df496e7.ttf") format("truetype"), url("/assets/fonts/Avenir/68f0375d-f66f-4f88-822a-5a0b61d2efc0.svg#68f0375d-f66f-4f88-822a-5a0b61d2efc0") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next";
  src: url("/assets/fonts/Avenir/8316f326-afe6-49dc-a5d8-cd1d521182c2.eot?#iefix");
  src: url("/assets/fonts/Avenir/8316f326-afe6-49dc-a5d8-cd1d521182c2.eot?#iefix") format("eot"), url("/assets/fonts/Avenir/395aeb70-3407-45d6-91b3-3d9a114af9bd.woff2") format("woff2"), url("/assets/fonts/Avenir/5f0686cb-9605-4656-8b38-9b8ca092ca9b.woff") format("woff"), url("/assets/fonts/Avenir/5fafc2a7-1ec9-4ba2-8d0b-836dfae0023b.ttf") format("truetype"), url("/assets/fonts/Avenir/f2ab6bd3-14da-4c86-949e-eddf030a8147.svg#f2ab6bd3-14da-4c86-949e-eddf030a8147") format("svg");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "AvenirNextLTPro";
  src: url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.eot?#iefix");
  src: url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.eot?#iefix") format("eot"), url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.woff2") format("woff2"), url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.woff") format("woff"), url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.ttf") format("truetype"), url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "AvenirNextLTPro";
  src: url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Demi.eot?#iefix");
  src: url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Demi.eot?#iefix") format("eot"), url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Demi.woff2") format("woff2"), url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Demi.woff") format("woff"), url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Demi.ttf") format("truetype"), url("/assets/fonts/AvenirNextLTPro/AvenirNextLTPro-Demi.svg#AvenirNextLTPro-Demi") format("svg");
  font-weight: 500;
  font-style: normal;
}
html {
  height: 100%;
  min-height: 100%;
}
body {
  background-color: var(--desktop-background-color);
  background-image: var(--desktop-background-image);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: var(--font-familiy);
  font-size: 15px;
  height: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  color: var(--general-text-color);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}
iframe {
  border: none;
}
/* SPINNER */
.fa-spin {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
/* WRAPPERS */
.page-wrapper {
  height: 100%;
  min-height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media only screen and (max-width: 767px) {
  :root {
    --native-header-background-color: var(--general-background-color);
  }
  body {
    background-color: var(--general-background-color);
    background-image: none;
  }
}
.password-strength-container {
  opacity: 0;
  transition: all ease-in-out 0.2s;
  text-align: right;
  height: 0;
}
.password-strength-container.active {
  height: 22px;
  margin-top: 5px;
  opacity: 1;
}
.password-strength-container.active span {
  font-size: 12px;
  font-weight: 500;
  opacity: 1;
  color: var(--general-text-color);
}
.password-strength-container.active .password-strength-bar div[class^="password-strength-bar-"] {
  height: 5px;
  border-radius: var(--general-border-radius);
}
.password-strength-container span {
  font-size: 0;
  opacity: 0;
  color: #626262;
  transition: all ease-in-out 0.2s;
}
.password-strength-container[data-password-strength-value="1"] .password-strength-bar-1 {
  background: #EB6262;
}
.password-strength-container[data-password-strength-value="1"] span {
  color: #EB6262;
}
.password-strength-container[data-password-strength-value="2"] .password-strength-bar-1,
.password-strength-container[data-password-strength-value="2"] .password-strength-bar-2 {
  background: #FFBD39;
}
.password-strength-container[data-password-strength-value="2"] span {
  color: #FFBD39;
}
.password-strength-container[data-password-strength-value="3"] .password-strength-bar-1,
.password-strength-container[data-password-strength-value="3"] .password-strength-bar-2,
.password-strength-container[data-password-strength-value="3"] .password-strength-bar-3 {
  background: #96C75D;
}
.password-strength-container[data-password-strength-value="3"] span {
  color: #96C75D;
}
.password-strength-container[data-password-strength-value="4"] .password-strength-bar-1,
.password-strength-container[data-password-strength-value="4"] .password-strength-bar-2,
.password-strength-container[data-password-strength-value="4"] .password-strength-bar-3,
.password-strength-container[data-password-strength-value="4"] .password-strength-bar-4 {
  background: #4FC990;
}
.password-strength-container[data-password-strength-value="4"] span {
  color: #4FC990;
}
.password-strength-container .password-strength-bar {
  width: 100%;
  transition: height ease-in-out 0.2s;
  display: flex;
  flex-direction: row;
}
.password-strength-container div[class^="password-strength-bar-"] {
  background: #e8e8e8;
  transition: background ease-in-out 0.5s;
  height: 0;
  flex: 1 1 auto;
  margin-right: 2px;
}
.password-strength-container div[class^="password-strength-bar-"]:last-child {
  margin-right: 0;
}
.btn {
  display: flex;
  height: 45px;
  line-height: 45px;
  max-width: 320px;
  border-radius: var(--general-border-radius);
  padding: 0 20px;
  background: var(--button-background-color);
  color: var(--button-text-color);
  text-decoration: none;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  margin: 0 auto;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  transition: background ease-in-out 0.3s;
  font-family: var(--font-family);
  box-shadow: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.btn i + span {
  margin-left: 10px;
}
.btn span {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.btn:hover {
  text-decoration: none;
  background: rgba(var(--button-background-color-rgb), 0.8);
}
.btn:disabled {
  background: var(--inactive-color);
}
.btn:disabled:hover {
  background: rgba(var(--inactive-color-rgb), 0.8);
  cursor: not-allowed;
}
.btn:disabled.loading {
  background: rgba(var(--inactive-color-rgb), 0.8);
}
button.btn {
  width: 100%;
}
.btn + .btn {
  margin-top: 10px;
}
input[type=text],
input[type=tel],
input[type=password],
.form-control-static {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 43px;
  width: 100%;
  max-width: 320px;
  padding: 0 10px;
  display: block;
  height: 45px;
  line-height: 45px;
  border-radius: var(--general-border-radius);
  border: 1px solid transparent;
  background: var(--background-highlight-color);
  padding: 0 15px 0 15px;
  box-sizing: border-box;
  font-size: 14px;
  position: relative;
  color: var(--general-text-color);
  outline: none;
  transition: border-color ease-in-out 0.3s;
  font-family: 'AvenirNextLTPro', 'Avenir Next', 'Arial', 'Verdana', 'Helvetica', 'sans-serif';
  box-shadow: none;
}
input[type=text]:hover,
input[type=tel]:hover,
input[type=password]:hover,
.form-control-static:hover {
  border-color: var(--general-border-color);
}
input[type=text]::placeholder,
input[type=tel]::placeholder,
input[type=password]::placeholder,
.form-control-static::placeholder {
  color: var(--general-text-color);
  opacity: 0.5;
}
input[type=text] + i,
input[type=tel] + i,
input[type=password] + i,
.form-control-static + i {
  font-size: 20px;
  position: absolute;
  top: 12px;
  left: 8px;
}
input[type=text] + img,
input[type=tel] + img,
input[type=password] + img,
.form-control-static + img {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 12px;
  left: 8px;
}
.form-control-static {
  user-select: none;
  vertical-align: middle;
  cursor: not-allowed;
}
.form-control-static span,
.form-control-static + i {
  opacity: 0.4;
}
input[type=text]:focus,
input[type=tel]:focus,
input[type=password]:focus {
  border-color: var(--general-inactive-color);
}
input[type=text].has-error,
input[type=tel].has-error,
input[type=password].has-error {
  border-color: rgba(var(--warning-color-rgb), 0.5);
}
input[type=text].has-error:focus,
input[type=tel].has-error:focus,
input[type=password].has-error:focus {
  border-color: var(--warning-color);
}
.has-error input[type=text],
.has-error input[type=tel],
.has-error input[type=password] {
  border-color: rgba(var(--warning-color-rgb), 0.5);
}
.has-error input[type=text]:focus,
.has-error input[type=tel]:focus,
.has-error input[type=password]:focus {
  border-color: var(--warning-color);
}
.has-error input:-webkit-autofill,
.has-error input:-webkit-autofill:hover input:-webkit-autofill:focus {
  border-color: var(--warning-color) !important;
}
input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--general-text-color);
  /* Firefox */
  opacity: 1;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--general-text-color);
  opacity: 1;
}
input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--general-text-color);
  opacity: 1;
}
.form-group {
  display: block;
}
.form-group.form-group-with-icon {
  position: relative;
}
.form-group.form-group-with-icon input[type=text],
.form-group.form-group-with-icon input[type=tel],
.form-group.form-group-with-icon input[type=password],
.form-group.form-group-with-icon .form-control-static {
  padding-left: 40px;
}
/* PIN */
.form-control-pin {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.form-control-pin input[type="text"] {
  margin: 0 5px;
  flex: 1 1 auto;
  text-align: center;
}
.form-control-pin input[type="text"]:first-of-type {
  margin-left: 0;
}
.form-control-pin input[type="text"]:last-of-type {
  margin-right: 0;
}
.form-control-pin input[type="text"]:not(:disabled):not(:focus) {
  border-color: var(--general-border-color);
}
.form-submitting .form-control-pin input[type=text] {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: opacityPulse;
}
@keyframes opacityPulse {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.4;
  }
}
/* CHECKBOX */
.checkbox {
  display: inline-block;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.checkbox input[type=checkbox] {
  height: 1px;
  width: 1px;
  position: absolute;
  left: -9999px;
  top: 0;
}
.checkbox input[type=checkbox] + span {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  color: var(--general-text-color);
  vertical-align: top;
  opacity: 1;
  user-select: none;
  position: relative;
}
.checkbox input[type=checkbox] + span:before {
  display: inline-block;
  height: 20px;
  width: 20px;
  box-sizing: border-box;
  border-radius: var(--general-border-radius);
  background: transparent;
  border: 1px solid var(--general-border-color);
  opacity: 1;
  content: '';
  vertical-align: top;
  margin-right: 7px;
  transition: border ease-in-out 0.3s;
}
.checkbox input[type=checkbox] + span:hover:before {
  border-color: var(--general-text-color) !important;
}
.checkbox input[type=checkbox]:checked + span:before {
  border-color: rgba(var(--general-text-color-rgb), 0.6);
}
.checkbox input[type=checkbox]:checked + span:after {
  content: '\f00c';
  font-family: var(--fa-style-family-classic);
  font-weight: 400;
  position: absolute;
  left: 4px;
  top: 1px;
  width: 17px;
  height: 9px;
  font-size: 15px;
}
.rl-select {
  display: block;
  height: 45px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}
.rl-select .rl-select-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 30px 0 10px;
  border-radius: var(--general-border-radius);
  border: 1px solid transparent;
  background: var(--background-highlight-color);
  transition: border-color ease-in-out 0.3s;
  position: relative;
}
.rl-select .rl-select-input:after {
  content: '';
  display: block;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 5px 0 5px;
  border-color: var(--general-inactive-color) transparent transparent transparent;
}
.rl-select .rl-select-input:hover {
  border-color: var(--general-border-color);
}
.rl-select .rl-select-input.placeholder span {
  color: var(--general-text-color);
  opacity: 0.5;
}
.rl-select .rl-select-input > img {
  height: 26px;
  width: 26px;
  margin-right: 10px;
}
.rl-select .rl-select-overlay {
  display: none;
}
.rl-select .rl-select-options {
  position: absolute;
  max-height: 400px;
  top: calc(100% + 5px);
  border-radius: var(--general-border-radius);
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15);
  transition: 0.15s linear opacity;
  max-width: 100%;
  min-width: 300px;
  z-index: 4;
  display: none;
  flex-direction: column;
  width: 100%;
  border: 1px solid var(--general-border-color);
  background: var(--white-color);
  overflow: hidden;
}
.rl-select .rl-select-options.show {
  display: flex;
}
.rl-select .rl-select-options .rl-select-options-scroll-container {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.rl-select .rl-select-options .rl-select-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  padding: 0 15px;
  cursor: pointer;
}
.rl-select .rl-select-options .rl-select-option:hover {
  background: var(--background-highlight-color);
}
.rl-select .rl-select-options .rl-select-option:focus {
  background: rgba(var(--background-highlight-color-rgb), .7);
  outline-style: none;
  outline: none;
}
.rl-select .rl-select-options .rl-select-option > img {
  width: 26px;
  height: 26px;
  display: block;
  margin-right: 10px;
}
.rl-select .rl-select-options .rl-select-option > span {
  margin: 0 auto 0 0;
  line-height: 1;
  color: var(--text-on-white-color);
  overflow: hidden;
  text-overflow: ellipsis;
}
.rl-select .rl-select-options .rl-select-option .radio {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  flex: 0 0 auto;
  border: 2px solid var(--general-border-color);
}
.rl-select .rl-select-options .rl-select-option.selected > span {
  color: var(--highlight-color);
  font-weight: bold;
}
.rl-select .rl-select-options .rl-select-option.selected .radio {
  position: relative;
  border: 2px solid var(--highlight-color);
}
.rl-select .rl-select-options .rl-select-option.selected .radio:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background: var(--highlight-color);
  border-radius: 50%;
}
@media only screen and (max-width: 767px) {
  .rl-select .rl-select-options {
    position: fixed;
    max-height: 40vh;
    top: auto;
    bottom: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-bottom: calc(15px + env(safe-area-inset-bottom));
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 100%;
    display: flex;
    border-radius: 15px 15px 0 0;
    box-shadow: 0 7px 15px rgba(60, 72, 88, 0.1);
    transform: translate(0, 100%);
    transition: 0.2s cubic-bezier(0, 1, 0.5, 1) transform;
  }
  .rl-select .rl-select-options.show {
    transform: translate(0, 0);
  }
  .rl-select .rl-select-options:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 10px;
    width: 42px;
    height: 5px;
    background: var(--text-on-white-color);
    opacity: 0.3;
  }
  .rl-select .rl-select-overlay.show {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    background: rgba(0, 0, 0, 0.4);
  }
}
.rl-phone-select {
  display: flex;
  width: 100%;
  height: 45px;
  flex-direction: row;
}
.rl-phone-select .rl-select {
  width: 120px;
  flex: 0 0 auto;
}
.rl-phone-select .rl-select:after {
  content: '';
  display: block;
  position: absolute;
  height: 30px;
  width: 1px;
  background: var(--general-border-color);
  left: 119px;
  top: 50%;
  margin-top: -15px;
}
.rl-phone-select .rl-select .rl-select-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rl-phone-select input {
  flex: 1 1 auto;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 15px;
}
.login-wrapper {
  max-width: 500px;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  padding: 0 10px;
  margin: auto;
}
.login-wrapper .login-logo-container {
  display: block;
  max-width: 320px;
  width: 100%;
  box-sizing: border-box;
  height: 90px;
  margin: 0 auto 40px auto;
  padding: 0 20px;
}
.login-wrapper .login-logo-container .login-logo {
  background-image: var(--desktop-logo);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center bottom;
  display: block;
  height: 100%;
  width: 100%;
}
.login-wrapper .login-headline {
  text-align: center;
  font-size: 18px;
  color: var(--desktop-text-color);
  margin: -20px auto 40px auto;
  padding: 0;
  box-sizing: border-box;
}
.login-copyright {
  height: 70px;
  width: 100%;
  text-align: center;
  font-size: 13px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.login-copyright p {
  margin: 0;
  padding: 0;
  color: var(--desktop-text-color);
  display: block;
}
.login-copyright a,
.login-copyright b {
  color: var(--desktop-text-color);
  margin: 0;
  font-weight: bold;
  text-decoration: none;
  display: block;
}
.login-copyright a:hover {
  text-decoration: underline;
}
.login-footer {
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
}
.login-footer a {
  display: block;
  margin-bottom: 10px;
  text-decoration: none;
  color: var(--general-text-color);
  cursor: pointer;
}
.login-footer a i {
  margin-right: 5px;
}
.login-footer a:hover {
  text-decoration: underline;
}
.login-footer button {
  font-family: 'AvenirNextLTPro', 'Avenir Next', 'Arial', 'Verdana', 'Helvetica', 'sans-serif';
  background: none;
  border: none;
  text-decoration: none;
  color: var(--general-text-color);
  font-size: 14px;
  cursor: pointer;
}
.login-footer button:hover {
  text-decoration: underline;
}
.login-footer button i {
  margin-right: 5px;
}
.login-footer .switch-account {
  background: none;
  border: none;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  box-sizing: border-box;
  font-size: 14px;
  text-decoration: underline;
  color: var(--highlight-color);
}
.login-footer .switch-account:hover {
  opacity: 0.8;
}
.login-container {
  background: var(--general-background-color);
  border-radius: var(--general-border-radius);
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  color: var(--general-text-color);
  box-shadow: 0px 7px 15px 0px rgba(60, 72, 88, 0.102);
}
.login-container section {
  display: block;
  padding: 20px 30px;
  box-sizing: border-box;
}
.login-container section:first-child {
  padding-top: 35px;
}
.login-container section:last-child {
  padding-bottom: 35px;
}
.login-container section h2 {
  margin: 0 0 16px 0;
  font-size: 24px;
  text-align: center;
}
.login-container section h3 {
  margin: -10px 0 16px 0;
  font-size: 20px;
  text-align: center;
}
.login-container section p {
  text-align: center;
}
.login-container section p:first-child {
  margin-top: 0;
}
.login-container section p:last-child {
  margin-bottom: 0;
}
.login-container section section a:not(.btn) {
  color: var(--general-text-color);
  text-decoration: none;
  cursor: pointer;
}
.login-container section section a:not(.btn):hover {
  text-decoration: underline;
}
.login-container .section-split {
  display: block;
  margin: -10px 0 0 0;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  color: var(--general-text-color);
  opacity: 0.7;
}
.styled-link {
  color: var(--button-background-color);
  text-decoration: none;
  cursor: pointer;
}
.styled-link:disabled {
  color: var(--inactive-color);
}
.styled-link:hover:not(:disabled) {
  text-decoration: underline;
}
.styled-link.styled-link-padding {
  padding: 0 5px;
}
.styled-link-btn {
  display: flex;
  flex-direction: row;
  color: var(--button-background-color);
  font-family: var(--font-family);
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  margin: 0 0 15px 0;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  font-weight: 300;
}
.styled-link-btn span {
  display: inline-block;
}
.styled-link-btn span + i {
  margin-left: 6px;
}
.styled-link-btn i {
  font-size: 12px;
}
.styled-link-btn i + span {
  margin-left: 6px;
}
.styled-link-btn:disabled {
  color: var(--inactive-color);
}
.styled-link-btn:hover:not(:disabled) span {
  text-decoration: underline;
}
.login-page h1 {
  text-align: left;
  margin: 15px 0 20px 0;
}
.login-page form {
  margin: 0 auto;
  width: 100%;
  max-width: 420px;
  padding: 0 10px;
  box-sizing: border-box;
}
.login-page form fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
.login-page form input[type=text],
.login-page form input[type=tel],
.login-page form input[type=password],
.login-page form .form-control-static {
  max-width: 100%;
}
.login-page form .btn {
  max-width: 100%;
}
.login-page .form-intro {
  display: block;
  font-weight: bold;
  margin: 0 auto 25px auto;
  font-size: 16px;
  text-align: left;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
}
.login-page .form-intro + .form-group-intro {
  margin-top: -10px;
}
.login-page .form-group-intro {
  display: block;
  font-weight: 300;
  margin: 0 auto 15px auto;
  font-size: 14px;
  text-align: left;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
}
.login-page .form-group-intro + .form-group {
  margin-top: 20px;
}
.login-page .form-centered {
  text-align: center;
}
.login-page .form-group {
  margin: 0px 0 15px 0;
}
.login-page .form-group + button[type="submit"] {
  margin-top: 25px;
}
.login-page button[type="button"] + button[type="submit"] {
  margin-top: 25px;
}
.login-page input.password-control {
  padding-right: 40px;
}
.login-page .password-view-icon {
  height: 45px;
  line-height: 45px;
  float: right;
  width: 40px;
  text-align: center;
  margin-bottom: -45px;
  opacity: 0.7;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
.login-page .password-view-icon:hover {
  opacity: 1;
}
.login-page .login-error {
  margin: -6px 0 12px 0;
}
.login-page .login-error ul {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}
.login-page .login-error ul li {
  display: block;
  color: #eb6262;
  font-size: 13px;
}
.login-page .login-remember {
  margin-top: -4px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.login-page .login-remember .checkbox {
  flex: 0 0 auto;
}
.login-page .login-remember a {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  box-sizing: border-box;
  font-size: 14px;
  text-decoration: underline;
  color: var(--general-text-color);
  flex: 0 0 auto;
  margin-left: auto;
}
.login-page a.back-button {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  box-sizing: border-box;
  font-size: 14px;
  text-decoration: underline;
  color: var(--general-text-color);
}
.login-page a.back-button:hover {
  opacity: 0.8;
}
.login-page .hidden-submit {
  display: none !important;
}
/* PROVIDER BUTTONS */
.provider-list {
  display: block;
  padding: 0 10px;
  list-style: none;
  margin: 0 auto;
  max-width: 420px;
  box-sizing: border-box;
}
.provider-list li {
  margin: 0 0 12px 0;
  padding: 0;
  list-style: none;
}
.provider-list li .btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  max-width: 100%;
  height: auto;
  min-height: 45px;
}
.provider-list li .btn:before {
  display: block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 20px;
  margin-right: 10px;
  position: relative;
  font-family: var(--fa-style-family-brands);
  text-align: center;
  flex: 0 0 auto;
}
.provider-list li .btn.provider-microsoft {
  background: #2E7AC8;
  color: #FFFFFF;
}
.provider-list li .btn.provider-microsoft:before {
  content: "\f17a";
}
.provider-list li .btn.provider-google {
  background: #EB4132;
  color: #FFFFFF;
}
.provider-list li .btn.provider-google:before {
  content: "\f1a0";
}
.provider-list li .btn.provider-okta {
  background: #000000;
  color: #FFFFFF;
}
.provider-list li .btn.provider-okta:before {
  content: "\f0a3";
  font-family: var(--fa-style-family-classic);
  font-weight: 400;
}
.provider-list li .btn span {
  display: block;
  line-height: 16px;
  white-space: initial;
  flex: 1 1 auto;
}
/* RESPONSIVE */
@media only screen and (max-width: 767px) {
  .page-wrapper {
    height: auto;
    background: var(--general-background-color);
  }
  .login-wrapper {
    max-width: 100%;
    padding: 0;
    display: block;
  }
  .login-wrapper .login-logo-container {
    max-width: 260px;
    margin-bottom: 20px;
    padding: 0 30px;
  }
  .login-wrapper .login-logo-container .login-logo {
    background-image: var(--logo);
  }
  .login-wrapper .login-headline {
    color: var(--general-text-color);
    font-size: 15px;
    margin-top: 0;
    padding: 0 20px;
    font-weight: normal;
  }
  .login-container {
    margin: 0;
    border-radius: 0;
    background: none;
    min-height: 150px;
    box-shadow: none;
  }
  .login-container section {
    padding: 15px 10px;
  }
  .login-container section:first-child {
    padding-top: 12px;
  }
  .login-container section:last-child {
    padding-bottom: 12px;
  }
  .login-copyright {
    height: 50px;
    font-size: 11px;
  }
  .login-copyright p,
  .login-copyright a,
  .login-copyright b {
    color: var(--general-text-color);
  }
  .login-footer {
    margin-top: 0;
  }
  .login-footer a,
  .login-footer button {
    color: var(--general-text-color);
  }
}
@media only screen and (min-width: 768px) {
  .login-page form,
  .provider-list {
    max-width: 400px;
  }
}
.universal-body {
  background-color: var(--general-background-color);
  background-image: none;
}
.universal-wrapper {
  height: 100%;
  display: flex;
  width: 100%;
  overflow: hidden;
  position: relative;
  flex-direction: column;
}
.universal-wrapper .universal-body-container {
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
  background-color: #000;
  background-image: url('/assets/universal-bg.png');
  background-size: cover;
  background-position: center bottom;
  display: flex;
  flex-direction: column;
}
.universal-wrapper .universal-body-container .logo-container {
  height: 55%;
  width: 100%;
  flex: 0 1 auto;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
}
.universal-wrapper .universal-body-container .logo-container h1 {
  display: block;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 0 15px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  color: #fff;
}
.universal-wrapper .universal-body-container .logo-container img {
  width: 250px;
  height: 60px;
  margin-top: 10px;
}
.universal-wrapper .universal-body-container .slider-container {
  height: 45%;
  width: 100%;
  position: relative;
  flex: 0 1 auto;
}
.universal-wrapper .universal-body-container .slider-container:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}
.universal-wrapper .universal-body-container .slider-container .slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: 2;
  transition: all ease-in-out 1s;
}
.universal-wrapper .universal-body-container .slider-container .slide.active {
  opacity: 1;
  z-index: 4;
}
.universal-wrapper .universal-body-container .slider-container .slide-body {
  width: 280px;
  height: auto;
  max-height: 100%;
  text-align: center;
  margin: 0;
  padding: 10px;
  font-weight: 400;
  color: #fff;
  font-size: 16px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
}
.universal-wrapper .universal-body-container .slider-container .slide-body a {
  display: inline;
  color: var(--highlight-color);
}
.universal-wrapper .universal-form-container {
  min-height: 230px;
  height: 230px;
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.universal-wrapper .universal-form-container form {
  width: 100%;
  max-width: 340px;
  margin: 0 auto;
  padding: 30px 15px;
  box-sizing: border-box;
}
.universal-wrapper .universal-form-container form .form-group {
  width: 100%;
  position: relative;
}
.universal-wrapper .universal-form-container form .input-remove-btn {
  position: absolute;
  display: none;
  font-size: 18px;
  cursor: pointer;
  top: 0;
  right: 0;
  height: 45px;
  line-height: 45px;
  width: 40px;
  text-align: center;
  color: var(--general-text-color);
}
.universal-wrapper .universal-form-container form .input-remove-btn.show {
  display: block;
}
.universal-wrapper .universal-form-container form input[type=text] {
  margin-bottom: 12px;
  padding-right: 40px;
}
.universal-wrapper .universal-form-container form label {
  text-align: center;
  display: block;
  width: 100%;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 500;
}
.universal-wrapper .universal-login-error {
  margin: -5px 0 9px 0;
}
.universal-wrapper .universal-login-error ul {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}
.universal-wrapper .universal-login-error ul li {
  display: block;
  color: var(--warning-color);
  font-size: 13px;
}
.universal-help-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;
  border: 0;
  box-shadow: none;
  width: 55px;
  height: 55px;
  line-height: 55px;
  background: none;
  color: #fff;
  font-size: 25px;
  cursor: pointer;
}
.universal-help-button:hover {
  opacity: 0.8;
}
.universal-help-container {
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  color: #2d2d2d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  display: none;
  opacity: 0;
  transition: opacity ease-in-out 0.3s;
}
.universal-help-container.active {
  display: flex;
}
.universal-help-container.active.show {
  opacity: 1;
}
.universal-help-container .universal-help-button {
  color: #2d2d2d;
}
.universal-help-container .universal-help-body {
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box;
  max-width: 400px;
  flex: 0 0 auto;
  margin: 0 auto;
}
.universal-help-container .universal-help-body h2 {
  font-size: 25px;
  font-weight: 500;
  margin: 0;
}
.universal-help-container .universal-help-body h2 img {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  top: 5px;
  margin-left: 2px;
}
.universal-help-container .universal-help-body p {
  margin: 15px 0;
}
/* RESPONSIVE */
@media only screen and (min-width: 767px) {
  .universal-wrapper .universal-body-container .logo-container {
    height: 50%;
    box-sizing: border-box;
    padding-bottom: 150px;
  }
  .universal-wrapper .universal-body-container .slider-container {
    height: 50%;
    box-sizing: border-box;
  }
  .universal-wrapper .universal-body-container .slider-container .slide {
    top: 115px;
    bottom: auto;
    height: 180px;
  }
  .universal-wrapper .universal-body-container .slider-container .slide .slide-body {
    width: 320px;
  }
  .universal-wrapper .universal-form-container {
    position: absolute;
    width: 500px;
    max-width: 500px;
    top: 50%;
    left: calc(50% - 250px);
    margin-top: -115px;
    background: var(--general-background-color);
    border-radius: var(--general-border-radius);
    box-shadow: 0px 7px 15px 0px rgba(60, 72, 88, 0.102);
  }
}
.multibrand-body {
  display: flex;
  background-color: var(--general-background-color);
}
.multibrand-wrapper {
  max-width: 480px;
  width: 100%;
  margin: auto;
}
.multibrand-wrapper .logo-container-empty {
  display: block;
  width: 100%;
  height: 40px;
}
.multibrand-wrapper .logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 480px;
  width: 100%;
  box-sizing: border-box;
  height: 150px;
  margin: 0 auto;
  padding: 30px 40px;
}
.multibrand-wrapper .logo-container .logo {
  display: block;
  max-height: 100%;
  max-width: 100%;
}
.multibrand-wrapper .intro-text {
  display: block;
  max-width: 480px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin: 0 auto 25px auto;
}
.multibrand-wrapper .description-text {
  display: block;
  max-width: 480px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  text-align: center;
  margin: 0 auto 25px auto;
  font-weight: 300;
  font-size: 14px;
}
.multibrand-wrapper .app-container {
  display: flex;
  max-width: 480px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}
.multibrand-wrapper .app-container .app {
  display: flex;
  flex: 0 0 auto;
  width: 50%;
  margin-bottom: 20px;
  text-decoration: none;
  flex-direction: column;
}
.multibrand-wrapper .app-container .app:hover .app-icon {
  opacity: 0.8;
}
.multibrand-wrapper .app-container .app .app-icon {
  display: block;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  background-position: center center;
  background-size: cover;
  border-radius: 20px;
  box-shadow: 0px 7px 15px 0px rgba(60, 72, 88, 0.3);
  transition: opacity ease-in-out 0.3s;
}
.multibrand-wrapper .app-container .app .app-name {
  display: block;
  margin: 10px auto 0 auto;
  text-align: center;
  color: var(--general-text-color);
  font-weight: bold;
  padding: 0 10px;
}
@media only screen and (min-width: 480px) {
  .multibrand-wrapper .app-container .app {
    width: 33%;
  }
}
@media only screen and (min-width: 768px) {
  .multibrand-wrapper .logo-container {
    position: relative;
    margin-bottom: -100px;
    top: -150px;
    margin-top: 150px;
  }
  .multibrand-body {
    background-color: var(--desktop-background-color);
  }
  .multibrand-body-container {
    background: var(--general-background-color);
    border-radius: var(--general-border-radius);
    box-shadow: 0px 7px 15px 0px rgba(60, 72, 88, 0.102);
    padding: 0 15px 20px 15px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.error-page .center {
  text-align: center;
}
.error-page .request-id {
  display: block;
  text-align: center;
  font-size: 12px;
  opacity: 0.7;
  text-transform: uppercase;
}
.error-page form {
  text-align: center;
}
