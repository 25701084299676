html {
    height: 100%;
    min-height: 100%;
}

body {
    background-color: var(--desktop-background-color);
    background-image: var(--desktop-background-image);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    font-family: var(--font-familiy);
    font-size: 15px;
    height: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    color: var(--general-text-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}

iframe {
    border: none;
}

/* SPINNER */
.fa-spin {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}

/* WRAPPERS */
.page-wrapper {
    height: 100%;
    min-height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

@media only screen and (max-width: 767px) {
    :root {
        --native-header-background-color: var(--general-background-color);
    }

    body {
        background-color: var(--general-background-color);
        background-image: none;
    }
}