.password-strength-container {
    opacity: 0;
    transition: all ease-in-out 0.2s;
    text-align: right;
    height: 0;
}

.password-strength-container.active {
    height: 22px;
    margin-top: 5px;
    opacity: 1;
}

.password-strength-container.active span {
    font-size: 12px;
    font-weight: 500;
    opacity: 1;
    color: var(--general-text-color);
}

.password-strength-container.active .password-strength-bar div[class^="password-strength-bar-"] {
    height: 5px;
    border-radius: var(--general-border-radius);
}

.password-strength-container span {
    font-size: 0;
    opacity: 0;
    color: #626262;
    transition: all ease-in-out 0.2s;
}

.password-strength-container[data-password-strength-value="1"] .password-strength-bar-1 {
    background: #EB6262;
}

.password-strength-container[data-password-strength-value="1"] span {
    color: #EB6262;
}

.password-strength-container[data-password-strength-value="2"] .password-strength-bar-1,
.password-strength-container[data-password-strength-value="2"] .password-strength-bar-2 {
    background: #FFBD39;
}

.password-strength-container[data-password-strength-value="2"] span {
    color: #FFBD39;
}

.password-strength-container[data-password-strength-value="3"] .password-strength-bar-1,
.password-strength-container[data-password-strength-value="3"] .password-strength-bar-2,
.password-strength-container[data-password-strength-value="3"] .password-strength-bar-3 {
    background: #96C75D;
}

.password-strength-container[data-password-strength-value="3"] span {
    color: #96C75D;
}

.password-strength-container[data-password-strength-value="4"] .password-strength-bar-1,
.password-strength-container[data-password-strength-value="4"] .password-strength-bar-2,
.password-strength-container[data-password-strength-value="4"] .password-strength-bar-3,
.password-strength-container[data-password-strength-value="4"] .password-strength-bar-4 {
    background: #4FC990;
}

.password-strength-container[data-password-strength-value="4"] span {
    color: #4FC990;
}

.password-strength-container .password-strength-bar {
    width: 100%;
    transition: height ease-in-out 0.2s;
    display: flex;
    flex-direction: row;
}

.password-strength-container div[class^="password-strength-bar-"] {
    background: #e8e8e8;
    transition: background ease-in-out 0.5s;
    height: 0;
    flex: 1 1 auto;
    margin-right: 2px;
}

.password-strength-container div[class^="password-strength-bar-"]:last-child {
    margin-right: 0;
}